import { ReactElement } from 'react';
import Link from 'components/UI/Link';
import DirectionsOutlinedIcon from '@material-ui/icons/DirectionsOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Location } from 'pages/locations/index';
import ScheduleAppointmentCta from 'components/UI/ScheduleAppointmentCta';
import { formatPhoneNumber } from 'lib/util';

const DIRECTIONS_URL = 'https://www.google.com/maps/dir/?api=1&destination=';

type MapCardProps = {
  location: Location;
  className?: string;
};

const buildDirectionsUrl = (loc: Location): string =>
  encodeURI(`${DIRECTIONS_URL}${loc.address1} ${loc.city},${loc.state} ${loc.zipCode}`);

export default function MapCard({ location, className }: MapCardProps): ReactElement {
  return (
    <div
      style={{ width: '240px', top: '5px', left: '-115px' }}
      className={`relative flex flex-col bg-white p-5 z-30 cursor-default ${className}`}
    >
      <div className="font-bold text-lg mb-2">{location.name}</div>

      <address className="not-italic mb-2">
        {location.address1} {location.address2}
        <br />
        {location.city}, {location.state} {location.zipCode}
      </address>

      {location.currentDistance && (
        <div className="flex items-center mb-2">
          <LocationOnIcon className="text-tertiary-actual mr-2" />{' '}
          {location.currentDistance.toPrecision(3)} miles
          <a
            href={buildDirectionsUrl(location)}
            target="_blank"
            className="flex ml-2 items-center text-tertiary-actual underline"
            rel="noreferrer"
          >
            <DirectionsOutlinedIcon className="mr-2" />
            Directions
          </a>
        </div>
      )}

      <a className="underline mb-2" href={`tel: ${location.phoneNumber}`}>
        {formatPhoneNumber(location.phoneNumber)}
      </a>

      <ScheduleAppointmentCta
        location={location}
        linkStyle
        classNames="mb-3 mt-3 self-center text-tertiary-actual"
      />

      <Link slug={`locations/${location.slug}`}>
        <a className="self-center">View Location Details</a>
      </Link>
    </div>
  );
}
