import React, { ReactElement, useEffect, useRef, useState, MutableRefObject } from 'react';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import ListIcon from '@material-ui/icons/List';
import cmsClient from 'lib/cms/client';
import {
  BrandedSiteProps,
  getServerSidePropsWithErrors,
  cacheDirective,
  getContentfulSiteData,
  isInvalid,
  withSiteData,
  getContentfulPagesData,
} from 'lib/routing';
import type { EntryCollection } from 'contentful';
import {
  IPageLocationFields,
  IMetaLocationsSharedDataFields,
  IComponentHeroInlineCircleFields,
  IFragmentLocationBanner,
} from 'types/contentful';

import Layout from 'components/UI/Layout';
import LocationAutocomplete, { PlaceType } from 'components/UI/LocationAutocomplete';
import LocationCard from 'components/UI/LocationCard';
import MapCard from 'components/UI/MapCard';
import Map from 'components/UI/Map';
import MapMarker from 'components/UI/MapMarker';
import NoResults from 'components/UI/NoResults';
import HeroInlineCircle from 'components/Contentful/Hero/HeroInlineCircle';
import { LOCATION_SELECT_FIELDS } from 'lib/consts';
import SharedLocationDataContext from 'context/SharedLocationDataContext';
import { addItemToLocalStorage, getDistance, getItemFromLocalStorage } from 'lib/util';
import UserMarkerSvg from 'components/UI/MapMarker/UserMarkerSvg';
import LocationMarkerSvg from 'components/UI/MapMarker/LocationMarkerSvg';

const MAP_DEFAULTS = {
  center: {
    lat: 45,
    lng: -98.5795,
  },
};

const DEFAULT_RADIUS = 100;
const DEFAULT_LOCATION_ZOOM = 10;
const INITIAL_MAP_ZOOM = 4;

const RADII = [5, 10, 20, 35, 50, 100];

const SAVED_LOCATION_KEY = 'finder-saved-location';
const SAVED_RADIUS_KEY = 'finder-saved-radius';
const SAVED_MOBILE_MAP_VIEW = 'saved-mobile-view';

export type Location = IPageLocationFields & {
  currentDistance?: number;
  sysId: string;
};

type MapCoord = {
  lat: number;
  lng: number;
};

type UserLocation = {
  map: MapCoord;
  place?: PlaceType;
};

type FinderProps = {
  preview: boolean;
  hero: IComponentHeroInlineCircleFields | null;
  hideEyeOnPin: boolean;
  markerIcon?: string;
  locations: Location[];
  siteData: BrandedSiteProps;
  currentSlug: string;
  title: string;
  locationBanners: IFragmentLocationBanner[];
  scheduleApptCtaText: string;
};

export default function Finder({
  preview,
  title,
  hideEyeOnPin,
  markerIcon = 'Eye-V01',
  currentSlug,
  siteData,
  hero,
  locations,
  locationBanners,
  scheduleApptCtaText,
}: FinderProps): ReactElement {
  const [radius, setRadius] = useState<number>(DEFAULT_RADIUS);
  const [userLocation, setUserLocation] = useState<UserLocation | null>(null);
  const [foundLocations, setFoundLocations] = useState(locations);
  const [activeCard, setActiveCard] = useState<null | Location>(null);
  const [activeLocation, setActiveLocation] = useState<null | Location>(null);
  const [
    autocompleteService,
    setAutocompleteService,
  ] = useState<google.maps.places.AutocompleteService | null>(null);
  const [searched, setSearched] = useState(false);
  const [showMapView, setShowMapView] = useState(false);
  const mapInstance = useRef() as MutableRefObject<google.maps.Map>;
  const geoCoder = useRef() as MutableRefObject<google.maps.Geocoder>;
  const mapContainer = useRef() as MutableRefObject<HTMLDivElement>;

  const setInitialSearchPosition = (userLocation: UserLocation | null): void => {
    if (!mapInstance.current || userLocation === null) return undefined;
    mapInstance.current.setCenter(
      new google.maps.LatLng(userLocation.map.lat, userLocation.map.lng)
    );
    mapInstance.current.setZoom(DEFAULT_LOCATION_ZOOM);
  };

  /**
   * Sets initial zoom/position when a user location is selected.
   */
  useEffect(() => {
    setInitialSearchPosition(userLocation);
  }, [userLocation]);

  /**
   * Center position on active card
   */
  useEffect(() => {
    if (!mapInstance.current || activeCard === null) return undefined;
    mapInstance.current.panTo(new google.maps.LatLng(activeCard.map.lat, activeCard.map.lon));
  }, [activeCard]);

  /**
   * When radius or user's selected location changes,
   * find all locations within range and sort.
   */
  useEffect(() => {
    if (userLocation === null) {
      setFoundLocations(locations);
      return;
    }

    const nearby = locations
      .map((loc) => {
        const coords = loc.map;
        const distance = getDistance(
          userLocation.map.lat,
          userLocation.map.lng,
          coords.lat,
          coords.lon
        );

        return { ...loc, currentDistance: distance };
      })
      .filter((loc) => loc.currentDistance <= radius)
      .sort((a, b) => a.currentDistance - b.currentDistance);

    if (nearby.length) {
      addItemToLocalStorage(SAVED_LOCATION_KEY, JSON.stringify(userLocation));
      addItemToLocalStorage(SAVED_RADIUS_KEY, radius.toString());
    }

    setFoundLocations(nearby);
    setSearched(true);
  }, [locations, radius, userLocation]);

  /**
   * This callback is triggered when the map and maps api have been successfully loaded.
   * @param param
   */
  const googleApiLoaded = ({ map }: { map: google.maps.Map }): void => {
    setAutocompleteService(new google.maps.places.AutocompleteService());
    geoCoder.current = new google.maps.Geocoder();
    mapInstance.current = map;
    google.maps.event.trigger(map, 'resize');
    const savedLocation = getItemFromLocalStorage(SAVED_LOCATION_KEY);
    const savedRadius = getItemFromLocalStorage(SAVED_RADIUS_KEY);
    const savedMobileMapView = getItemFromLocalStorage(SAVED_MOBILE_MAP_VIEW);
    if (savedLocation) setUserLocation(JSON.parse(savedLocation));
    if (savedRadius) setRadius(Number(savedRadius));
    if (savedMobileMapView) setShowMapView(savedMobileMapView === 'true');
  };

  /**
   * Get location from autocomplete and geocode it.
   * @param place
   * @returns
   */
  const locationSelected = (place: PlaceType): void => {
    if (place === null) return undefined;
    geoCoder.current.geocode({ placeId: place.place_id }, (results) => {
      if (!results) return undefined;
      const result = results[0].geometry.location;
      setUserLocation({ place, map: { lat: result.lat(), lng: result.lng() } });
    });
  };

  const onUseMyLocation = (coords: GeolocationCoordinates): void => {
    setUserLocation({ map: { lat: coords.latitude, lng: coords.longitude } });
  };

  /**
   * Reset map data on show map view toggle
   */
  const toggleMapView = (showMapView: boolean): void => {
    if (showMapView) {
      setActiveCard(null);
      setActiveLocation(null);
    }
    addItemToLocalStorage(SAVED_MOBILE_MAP_VIEW, showMapView.toString());
    setShowMapView(showMapView);
  };

  const nextSeoProps = {
    title,
    description: `Use our interactive map to browse all of our ${siteData.siteName} locations and find the office closest to you. Then, book an appointment with one of our trusted eye doctors!`,
  };

  return (
    <Layout
      siteData={siteData}
      preview={preview}
      currentSlug={currentSlug}
      nextSeoProps={nextSeoProps}
    >
      <SharedLocationDataContext.Provider
        value={{ scheduleAppointmentCtaText: scheduleApptCtaText }}
      >
        {hero && (
          <div className="mb-6">
            <HeroInlineCircle {...hero} />
          </div>
        )}
        <div className="flex flex-col lg:flex-row mb-10">
          <div className="w-full lg:w-3/5">
            <div className="flex flex-col py-5 sm:flex-row">
              {autocompleteService && (
                <LocationAutocomplete
                  className="mx-5 mb-5 sm:mb-0 sm:w-2/3"
                  autocompleteService={autocompleteService}
                  onSelect={locationSelected}
                  onUseMyLocation={onUseMyLocation}
                  initValue={userLocation?.place}
                />
              )}
              {searched && (
                <span className="flex items-center shadow-lg mx-5 px-5 py-3 sm:w-1/3 h-56">
                  <label className="font-bold" htmlFor="search-radius">
                    Radius:
                  </label>
                  <select
                    id="search-radius"
                    value={radius}
                    onChange={({ target }) => setRadius(parseFloat(target.value))}
                    className="w-full"
                  >
                    {RADII.map((opt) => (
                      <option key={`radius-${opt}`} value={opt}>
                        {opt} miles
                      </option>
                    ))}
                  </select>
                </span>
              )}
            </div>
            <div
              className={`${showMapView ? 'block' : 'hidden'} flex justify-between lg:hidden p-3`}
            >
              <span className="font-bold text-sm opacity-50">
                {foundLocations.length && searched ? (
                  <>
                    {foundLocations.length} Results within {radius} miles
                  </>
                ) : (
                  <>{locations.length} Results Nationwide</>
                )}
              </span>
              <a
                tabIndex={0}
                role="button"
                className="block lg:hidden"
                onClick={() => toggleMapView(!showMapView)}
                onKeyDown={() => toggleMapView(!showMapView)}
              >
                <ListIcon /> List View
              </a>
            </div>
            <div
              className={`lg:h-map relative relative`}
              ref={mapContainer}
              style={{ height: showMapView ? '700px' : '' }}
            >
              <Map
                defaultZoom={INITIAL_MAP_ZOOM}
                center={userLocation?.map || MAP_DEFAULTS.center}
                onApiLoaded={googleApiLoaded}
                forceSetMapBounds={showMapView}
              >
                {userLocation && (
                  <MapMarker
                    key={'finder-user-location'}
                    lat={userLocation.map.lat}
                    lng={userLocation.map.lng}
                    style={{ zIndex: 20 }}
                  >
                    <UserMarkerSvg classNames="text-secondary-actual" />
                  </MapMarker>
                )}
                {foundLocations.map((loc) => (
                  <MapMarker
                    key={`marker-${loc.slug}`}
                    lat={loc.map.lat}
                    lng={loc.map.lon}
                    onClick={() => {
                      setActiveCard(activeCard !== loc ? loc : null);
                      setActiveLocation(activeLocation !== loc ? loc : null);
                    }}
                    focused={activeLocation === loc}
                    renderInfoCard={() => <MapCard location={loc} />}
                    showCard={activeCard === loc}
                  >
                    <LocationMarkerSvg
                      title={loc.name}
                      hideEyeOnPin={!!hideEyeOnPin}
                      classNames={
                        loc === activeLocation ? 'text-primary-actual' : 'text-tertiary-actual'
                      }
                      focused={activeLocation === loc}
                      markerIcon={markerIcon}
                    />
                  </MapMarker>
                ))}
              </Map>
            </div>
          </div>
          <div
            className={`${showMapView ? 'hidden' : 'block'} lg:block lg:w-2/5 lg:pl-16 lg:mt-20`}
          >
            <div className="flex justify-between mb-5 border-b-2 p-3">
              <span className="font-bold text-sm opacity-50">
                {foundLocations.length && searched ? (
                  <>
                    {foundLocations.length} Results within {radius} miles
                  </>
                ) : (
                  <>{locations.length} Results Nationwide</>
                )}
              </span>
              <a
                tabIndex={0}
                role="button"
                className="block lg:hidden"
                onClick={() => toggleMapView(!showMapView)}
                onKeyDown={() => toggleMapView(!showMapView)}
              >
                <ExploreOutlinedIcon /> Map View
              </a>
            </div>
            {foundLocations.length ? (
              <div className="lg:h-finder-overflow lg:overflow-y-auto divide-y-2 lg:p-2">
                {foundLocations.map(
                  (location): ReactElement => (
                    <div
                      key={`office-location-${location.slug}`}
                      className="transition ease-linear duration-100 shadow-none lg:hover:shadow-location-card"
                      onFocus={() => {
                        setActiveLocation(location);
                        setActiveCard(null);
                      }}
                      onBlur={() => setActiveLocation(null)}
                      onMouseOver={() => {
                        setActiveLocation(location);
                        setActiveCard(null);
                      }}
                      onMouseLeave={() => setActiveLocation(null)}
                      onTouchStart={() => {
                        setActiveLocation(location);
                        setActiveCard(null);
                      }}
                      onTouchCancel={() => setActiveLocation(null)}
                    >
                      <LocationCard locationBanners={locationBanners} location={location} />
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="flex justify-center content-center">
                <NoResults />
                <p className="ml-5 text-center flex flex-col justify-center">
                  {searched && foundLocations.length === 0 && (
                    <>
                      <span className="font-bold">
                        Whoops,
                        <br />
                        no results found in your area
                      </span>
                      <br />
                      Please try a different search location.
                    </>
                  )}
                  {!searched && <span className="font-bold">Type above to start a search.</span>}
                </p>
              </div>
            )}
          </div>
        </div>
      </SharedLocationDataContext.Provider>
    </Layout>
  );
}

export const getServerSideProps = getServerSidePropsWithErrors<FinderProps>(
  async ({ resolvedUrl, res, req, preview }) => {
    const host = req.headers.host;
    const queryOptions = {
      'fields.site.sys.contentType.sys.id': 'site',
      'fields.site.fields.id': host,
      limit: 1000,
      select: LOCATION_SELECT_FIELDS,
      content_type: 'pageLocation',
    };

    const siteData = await getContentfulSiteData({ req, res, resolvedUrl, preview });

    if (isInvalid(siteData)) {
      return siteData;
    }

    const contentfulData = await getContentfulPagesData<IPageLocationFields>({
      res,
      req,
      preview,
      queryOptions,
    });

    if (isInvalid(contentfulData)) {
      return withSiteData(contentfulData, siteData);
    }

    const client = cmsClient(preview);
    const locations = contentfulData.items;

    const sharedMeta: EntryCollection<IMetaLocationsSharedDataFields> = await client.getEntries({
      'fields.site.sys.contentType.sys.id': 'site',
      'fields.site.fields.id': host,
      select:
        'fields.officeFinderHero,fields.banners,fields.scheduleApptCtaText,fields.hideEyeIcon,fields.markerPinIcon',
      content_type: 'metaLocationsSharedData',
      include: 4,
    });

    res.setHeader('Cache-Control', cacheDirective(preview));

    return {
      props: {
        title: 'Find a Location Near You',
        hero: sharedMeta?.items[0]?.fields?.officeFinderHero?.fields || null,
        hideEyeOnPin: !!sharedMeta?.items[0]?.fields?.hideEyeIcon,
        markerIcon: sharedMeta?.items[0]?.fields?.markerPinIcon,
        locationBanners: sharedMeta?.items[0]?.fields?.banners || [],
        scheduleApptCtaText: sharedMeta?.items[0]?.fields?.scheduleApptCtaText || '',
        preview: preview ? true : false,
        locations: locations.map((loc) => ({ ...loc.fields, sysId: loc.sys.id })),
        currentSlug: 'locations',
        siteData,
      },
    };
  }
);
